.btn {
  @include display-flex(center, center);
  position: relative;
  font-weight: 700;
  font-size: 1rem;
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
  padding: .375rem 1.25rem;
  border-width: 2px;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }

  // Sizes
  &-xsm {
    padding: .188rem .75rem;
    font-size: $font-size-xsm;
  }
  &-sm {
    padding: .25rem .75rem;
    font-size: $font-size-sm;
  }
  &-md {
    padding: .5rem 1rem;
    font-size: $font-size-md;
  }
  &-lg {
    padding: .5rem 1.2rem;
  }

  // Colors
  &-primary {
    color: $primary-button-text !important;
    background-color: $primary-button-background;
    border-color: $primary-border-color;

    &:hover, &:active, &:focus {
      background-color: darken($primary-button-background, 6) !important;
      border-color: darken($primary-button-background, 6) !important;
    }

    &:disabled {
      background-color: #377065;
      border-color: #377065;
    }
  }

  &-outline-primary {
    color: $primary-button-background !important;
    border-color: $primary-button-background;

    &:hover, &:active, &:focus {
      color: $primary-button-text !important;
      background-color: $primary-button-background;
      border-color: $primary-button-background;
    }

    &:disabled {
      opacity: 0.3 !important;
    }
  }

  &-secondary {
    color: $secondary-button-text;
    background-color: $secondary-button-background;
    border-color: $secondary-border-color;

    &.disabled {
      color: $button-disabled-text;
    }
  }

  // Button spinner
  &--is-loading {
    padding-left: 1.75rem !important;

    &:before {
      content: '';
      position: absolute;
      inset: -1px;
      opacity: 0;
      background: #fff;
    }
    > .loader-spinner {
      position: absolute;
      left: 0;
      opacity: 1;
      margin-left: .25rem;
      margin-top: -.125rem;

      svg circle {
        stroke: #101573 !important;
        stroke-width: 4;
      }
    }
  }

  &:disabled {
    opacity: 0.85;
  }

  // Icon
  & > svg {
    margin-right: .375rem;
  }
}

.copy-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  padding: .25rem .5rem;
  font-size:.875rem;
  line-height: 1.5;
  max-width: 23rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  color: $color-white;
  border: 1px solid;
}
