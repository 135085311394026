.card {
  background: #10172a;
  border: 1px solid #0c1816;
  border-radius: .5rem;
  min-height: 18.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 1%) 0 0 1px, rgb(0 0 0 / 4%) 0 6px 6px, rgb(0 0 0 / 4%) 0 16px 24px, rgb(0 0 0 / 1%) 0 32px 48px;

  &-header {
    position: relative;
    padding: 1rem;
    border-bottom: 1px solid rgb(255 255 255 / 7%);

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #1a1a1a;
    }
  }

  &-body {
    padding: 1rem;

    @media (min-width: 768px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &-title {
    text-transform: uppercase;
    color: #fff;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.1;
    text-align: center;
  }

  &-description {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
    color: #666;
  }
}
