.label {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;

  // Colors
  &-dark {
    background-color: $color-darker;
  }

  // Types
  &-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }
}
