.tabs {
  @include display-flex();
  // Note: using 'unset' as it causes issues with tabs in container header
  align-items: unset !important;
  border-bottom: 1px solid $color-dark;
  height: 2.813rem;
  padding: 0;

  @media (min-width: 768px) {
    padding-left: 1rem;
  }

  &__item {
    @include display-flex(flex-end);
    color: $color-gray;
    font-size: 1rem;
    position: relative;
    margin: 0 1.5rem;
    padding-bottom: 1rem;
    @extend .transition-ease-primary;

    // Only if there are multiple tabs then chow pointer cursor
    &:not(:only-child) {
      cursor: pointer;
    }

    &:before {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      border-bottom: solid .125rem $color-primary;
      transform: scaleX(0);
      transition: transform .2s ease-in-out;
      @include border-radius(2px, 2px, 0, 0);
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active:before {
      border-bottom-color: $color-warning;
    }

    &--active,
    &:hover:not(&--active) {
      color: $color-white;
      opacity: 1;

      &:before {
        transform: scaleX(1.25);
      }
    }

    &--disabled {
      pointer-events: none;
    }

    &--disabled:hover {
      &:before {
        transform: scaleX(0) !important;
      }
    }

    > svg {
      margin-right: .75rem;
    }

    &__action {
      @include display-flex(center);
    }
  }

  // Flush
  // This is needed for tabs in page header
  &--flush {
    border-bottom: 0;
    margin-bottom: 0;
  }

  &--flat {
    padding-left: 0;

    .tabs__item {
      &:before {
        transform: scaleX(0) !important;
      }
    }
  }
}

.tab-content {
  margin-top: 1.5rem;

  &__item {
    display: none;

    &--active {
      display: block;
    }
  }
}
