.block-wrapper {
  background-color: rgb(255 255 255 / 4%);
  padding: 1.5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  border-radius: .5rem;

  &--dark {
    background: rgb(255 255 255 / 4%);
  }
}
