.headline {
  @include display-flex(center);
  flex-direction: column;

  &-title {
    font-weight: bold;
    font-size: 4.25rem;
    line-height: 1.4;
    margin-bottom: 0.5rem;
  }

  &-hint {
    max-width: 48rem;
    line-height: 1.6;
    margin-bottom: 0;
  }
}
