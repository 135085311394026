.input,
input[type=text],
input[type=password],
input[type=datetime],
input[type=number],
input[type=email],
input[type=phone],
input[type=search] {
  display: block;
  width: 100%;
  min-height: 2.125rem;
  padding: $input-padding;
  font-size: $font-size-sm;
  color: $color-black;
  background-color: $color-white;
  border: 1px solid $color-cool-gray;
  line-height: 1.5;
  outline: 0;
  -webkit-appearance: none;
  @include border-radius(.25rem);
  @include box-sizing(border-box);
  @include transition-ease(all, 0.2s, ease-in-out);
}

// Size
.input--lg {
  padding: 0.375rem 0.625rem;
}

// Input with bottom line only
input.input--line,
input.input--line:focus {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;

  &:not(:disabled) {
    border-radius: 0;
  }
}

input.input--line:not(:disabled) {
  background-color: transparent !important;
  padding-right: 0;
  padding-left: 0;
}

input.input--line-hover {
  border-bottom-color: transparent;

  &:hover:not(:disabled) {
    border-bottom-color: $color-light-gray;
  }
}

// Disabled input
input.input-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: $input-disabled-color;
}

.input-disabled,
input:disabled {
  background-color: $input-disabled-background-color;
}

input.input-transparent {
  color: #fff;
  background: rgb(0 0 0 / 18%);
  border: none;
  border-bottom: 1px solid #11251e;
  border-radius: 0;

  &:hover:not(:disabled), &:focus:not(:disabled) {
    background-color: rgb(255 255 255 / 9%);
  }
}
