body {
  margin: 0;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $text-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left;
  background-color: $background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 2rem 0;
  z-index: 99;
}

main {
  @media screen and (min-width: 992px) {
    padding-top: 3rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $color-white;

  > span {
    color: $color-info;
  }
}

section {
  padding: 4rem 0;

  @media screen and (max-width: 992px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

a {
  color: $color-link;
  text-decoration: none;

  &:hover, &:active, &:focus {
    color: $color-link-hover;
  }
}

ul {
  font-size: $font-size-h5
}

// Image
img {
  width: auto;
  max-width: 100%;
}

label {
  color: $color-gray;
  font-size: 16px;
}

::selection {
  color: #fff;
  background-color: $color-warning;
}

.image {
  &-md {
    width: 4rem;
    height: 4rem;
  }
}

// Section
.section {

  &-title {
    display: inline-block;
    font-size: 3.5rem;
    color: $color-white;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: .5rem;

    &--no-border {
      border: none;
    }
  }

  &-subtitle {
    color: white;
    font-size: 2rem;

    @media screen and (min-width: 768px) {
      font-size: 3rem;
    }
  }

  &-text {
    font-size: 20px;
    color: $color-pastel-green;
    line-height: 1.8;
    margin-bottom: 1.25rem;

    > b {
      color: $color-success;
    }
  }
}

.column-divider {
  @media screen and (min-width: 992px) {
    &-lg {
      border-right: 1px solid rgb(255 255 255 / 8%);

      &:last-child {
        border-right: none;
      }
    }
  }
}

.section-description {
  color: $color-slate-gray;
  margin-bottom: 1.25rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.3;
  padding: 0 1rem;

  &.large {
    font-size: 2rem;

    @media screen and (min-width: 768px) {
      font-size: 3rem;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0 11rem;
  }
}

.page-banner-caption {
  font-size: 1.7rem;
}

#home {
  ul {
    color: $color-slate-gray;
    text-align: left;
    margin-top: .5rem;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      margin: 2rem 0;
      line-height: 1.1;
      font-size: 1.5rem;

      .lead-text {
        display: block;
        color: white;
      }

      .subtext {
        font-size: 1rem;
        color: wheat;
      }

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        line-height: 1.3;
      }
    }

    &.right-section {
      @media screen and (min-width: 768px) {
        text-align: right;
      }
    }
  }

  .feature-button {
    min-width: 16rem;
    margin: 1rem 0;


    &:nth-of-type(2) {
      @media screen and (min-width:768px) {
        margin: 1rem auto;
      }
    }
  }

  .wheat-text {
    color: wheat;
  }

  .white-text {
    color: white;
  }

  .gray-text {
    color: $color-slate-gray;
  }
}

#privacy {
  table,
  th,
  td {
    padding: .75rem;
    border: 1px solid $color-white;
    border-collapse: collapse;
  }

  a {
    text-decoration: underline;
    color: #0020C2;
  }
}

#terms {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }
}

.animation {

  p {
    &[data-scroll='in'] {
      [data-char]:after {
        animation-name: glitch-switch;
      }
    }
  }

  .whitespace {
    margin: 0 .5rem;
  }

  .reveal--2 [data-char]:after {
    animation-iteration-count: var(--count);
  }

  p:nth-of-type(1) {
    position: absolute;
    opacity: 0;
  }

  .word {
    position: relative;
    display: inline-block;
  }

  [data-char] {
    color: transparent;
    position: relative;

    &:after {
      --txt: attr(data-char);
      animation-duration: .2s;
      animation-delay: .5s;
      animation-timing-function: steps(1);
      animation-fill-mode: backwards;
      content: var(--txt);
      color: white;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

#toast-container > div {
  opacity: 1;
}

.toast-error {
  background-color: #bd362f;
}

.toast-success {
  background-color: #51A351;
}

.toast-info {
  background-color: #2F96B4;
}

@keyframes glitch-switch {
  0% {
    content: var(--char-0);
  }

  10% {
    content: var(--char-1);
  }

  20% {
    content: var(--char-2);
  }

  30% {
    content: var(--char-3);
  }

  40% {
    content: var(--char-4);
  }

  50% {
    content: var(--char-5);
  }

  60% {
    content: var(--char-6);
  }

  70% {
    content: var(--char-7);
  }

  80% {
    content: var(--char-8);
  }

  90% {
    content: var(--char-9);
  }

  100% {
    content: var(--txt);
  }
}



